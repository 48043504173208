import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { projects } from '../data/projects';
import './Project.css';

function Project() {
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Louis' Projects";
	}, []);

	const containerVariants = {
		hidden: { opacity: 0 },
		visible: {
			opacity: 1,
			transition: {
				staggerChildren: 0.2
			}
		}
	};

	const projectVariants = {
		hidden: { y: 20, opacity: 0 },
		visible: {
			y: 0,
			opacity: 1,
			transition: {
				duration: 0.5
			}
		}
	};

	const handleProjectClick = (projectId) => {
		navigate(`/projects/${projectId}`);
	};

	return (
		<div className="projects-page">
			<motion.div 
				className="projects-header"
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.6 }}
			>
				<h1>My Projects</h1>
				<p>A collection of my work in web development and design</p>
			</motion.div>

			<motion.div 
				className="projects-grid"
				variants={containerVariants}
				initial="hidden"
				animate="visible"
			>
				{projects.map((project) => (
					<motion.div 
						key={project.id}
						className="project-card"
						variants={projectVariants}
						whileHover={{ y: -10 }}
						transition={{ duration: 0.3 }}
						onClick={() => handleProjectClick(project.id)}
					>
						<div className="project-image">
							<img 
								src={project.images[0].url} 
								alt={project.title} 
							/>
							<div className="project-overlay">
								<div className="project-links">
									<span className="view-details">View Details</span>
								</div>
							</div>
						</div>

						<div className="project-content">
							<div className="project-header">
								<h3>{project.title}</h3>
								<span className="project-year">{project.year}</span>
							</div>
							<p className="project-description">{project.shortDescription}</p>
							<div className="project-tech">
								{project.technologies.slice(0, 3).map((tech, index) => (
									<span key={index} className="tech-tag">{tech}</span>
								))}
								{project.technologies.length > 3 && (
									<span className="tech-tag">+{project.technologies.length - 3}</span>
								)}
							</div>
						</div>
					</motion.div>
				))}
			</motion.div>
		</div>
	);
}

export default Project;