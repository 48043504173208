export const projects = [
    {
        id: "melomap",
        title: "MeloMap",
        shortDescription: "A social music-sharing platform with location mapping",
        fullDescription: "MeloMap is a web application that allows users to discover and share music based on geographical locations. Users can pin their favorite songs to specific locations, creating a musical map of memories and discoveries.",
        year: "2024",
        link: "https://melo.louisleng.com",
        github: "https://github.com/LouisXO",
        technologies: ["Django", "React", "MySQL", "Apache", "Google Maps API"],
        images: [
            {
                url: "/images/projects/melomap/main.jpg",
                caption: "MeloMap Homepage"
            },
            {
                url: "/images/projects/melomap/feature1.jpg",
                caption: "Location-based Music Discovery"
            }
        ],
        features: [
            "Interactive map interface",
            "Social sharing capabilities",
            "Location-based music discovery",
            "Personal music collections"
        ],
        role: "Full Stack Developer",
        duration: "3 months",
        team: ["Louis Leng"],
        challenges: "Implementing real-time location updates while maintaining performance",
        outcome: "Successfully launched with 500+ active users"
    },
    {
        id: "h-island",
        title: "H-Island",
        shortDescription: "A quiz vacation matching website for SIDS",
        fullDescription: "H-Island helps promote economic recovery in Small Island Developing States through an interactive quiz system that matches travelers with their ideal island destinations.",
        year: "2023",
        link: "https://h-island.louisleng.com",
        github: "https://github.com/LouisXO",
        technologies: ["React", "SurveyJS", "Node.js", "MongoDB"],
        images: [
            {
                url: "/images/projects/h-island/main.jpg",
                caption: "H-Island Homepage"
            }
        ],
        features: [
            "Interactive quiz system",
            "Destination matching algorithm",
            "Travel recommendations",
            "Cultural information"
        ],
        role: "Frontend Developer",
        duration: "4 months",
        team: ["Louis Leng", "Hadar", "Hannan", "Mariam"],
        challenges: "Creating an engaging and accurate matching algorithm",
        outcome: "Featured in UW iSchool Research Symposium"
    }
    // Add more projects as needed
]; 