import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa';
import { projects } from '../data/projects';
import './ProjectDetail.css';

function ProjectDetail() {
    const { id } = useParams();
    const [project, setProject] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const projectData = projects.find(p => p.id === id);
        setProject(projectData);
        document.title = `${projectData?.title || 'Project'} - Louis' Portfolio`;
    }, [id]);

    if (!project) {
        return <div className="loading">Loading...</div>;
    }

    return (
        <motion.div 
            className="project-detail"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
            <div className="project-header">
                <h1>{project.title}</h1>
                <p className="project-year">{project.year}</p>
            </div>

            <div className="project-gallery">
                <div className="main-image">
                    <img 
                        src={project.images[currentImageIndex].url} 
                        alt={project.images[currentImageIndex].caption} 
                    />
                    <p className="image-caption">{project.images[currentImageIndex].caption}</p>
                </div>
                {project.images.length > 1 && (
                    <div className="image-thumbnails">
                        {project.images.map((image, index) => (
                            <img 
                                key={index}
                                src={image.url}
                                alt={`Thumbnail ${index + 1}`}
                                className={currentImageIndex === index ? 'active' : ''}
                                onClick={() => setCurrentImageIndex(index)}
                            />
                        ))}
                    </div>
                )}
            </div>

            <div className="project-links">
                <a href={project.github} target="_blank" rel="noopener noreferrer" className="github-link">
                    <FaGithub /> View on GitHub
                </a>
                <a href={project.link} target="_blank" rel="noopener noreferrer" className="live-link">
                    <FaExternalLinkAlt /> Live Demo
                </a>
            </div>

            <div className="project-content">
                <section className="project-overview">
                    <h2>Overview</h2>
                    <p>{project.fullDescription}</p>
                </section>

                <section className="project-tech">
                    <h2>Technologies Used</h2>
                    <div className="tech-tags">
                        {project.technologies.map((tech, index) => (
                            <span key={index} className="tech-tag">{tech}</span>
                        ))}
                    </div>
                </section>

                <section className="project-features">
                    <h2>Key Features</h2>
                    <ul>
                        {project.features.map((feature, index) => (
                            <li key={index}>{feature}</li>
                        ))}
                    </ul>
                </section>

                <section className="project-details-grid">
                    <div className="detail-item">
                        <h3>Role</h3>
                        <p>{project.role}</p>
                    </div>
                    <div className="detail-item">
                        <h3>Duration</h3>
                        <p>{project.duration}</p>
                    </div>
                    <div className="detail-item">
                        <h3>Team</h3>
                        <p>{project.team.join(', ')}</p>
                    </div>
                </section>

                <section className="project-challenges">
                    <h2>Challenges & Outcomes</h2>
                    <p><strong>Challenges:</strong> {project.challenges}</p>
                    <p><strong>Outcome:</strong> {project.outcome}</p>
                </section>
            </div>

            <div className="navigation-links">
                <Link to="/projects" className="back-link">← Back to Projects</Link>
            </div>
        </motion.div>
    );
}

export default ProjectDetail; 